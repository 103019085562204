<script setup>
import {
  Bars3Icon,
  BellIcon,
  UserIcon,
  XMarkIcon,
  Cog6ToothIcon,
  Cog8ToothIcon,
  ArrowRightOnRectangleIcon,
  CalendarIcon,
  CalendarDaysIcon,
} from "@heroicons/vue/24/outline";


const props = defineProps({
  user: {
    type: Object,
  }
});

let calendars = ref([]);

const fetchCalendars = async (client, user) => {
  const { data } = await client
    .from("calendars")
    .select("name, id")
    .eq("owner", user.value?.id);
  calendars.value = data;
};

onMounted(async () => {
  const client = await useSupabaseClient();
  const user = await useSupabaseUser();

  if (user.value && client) {
    fetchCalendars(client, user);
  }

  window.addEventListener("calendarListChanged", async () => {
    fetchCalendars(client, user);
  });
});

const actuRoute = computed(() => {
  const route = useRoute();
  return route;
});

</script>

<template>
  <div class="bg-white p-4 flex flex-col overflow-auto">
    <div class="">
      <button
        @click="$router.push('/calendars')"
        class="bg-primary-900 px-5 py-3 rounded-lg hover:bg-blue-600 text-light-900 font-semibold mb-16"
      >
        {{ $t('create_calendar') }}
      </button>
    </div>
    <div class="flex-grow">
      <div class="mb-4">
        <div class="flex justify-between items-center pb-5 pl-3">
          <h2 class="flex text-base text-dark-700 font-semibold">
            <CalendarIcon class="h-6 w-6 rounded-full mr-4" />
            {{ $t('my_calendars') }}
          </h2>
          <div
            class="flex items-center justify-center w-6 h-6 rounded-full bg-dark-100 text-dark-900 text-xs font-light ml-2"
          >
            {{ calendars.length }}
          </div>
        </div>
        <ul class="space-y-2">
          <li
            v-for="calendar in calendars"
            :key="calendar.name"
            class="text-dark-900 font-semibold py-3 pl-12 pr-12 rounded-lg [&.active]:bg-dark-50 hover:bg-dark-50 "
            :class="{ active: actuRoute.path === `/calendars/${calendar.id}` }"
          >
            <NuxtLink :to="`/calendars/${calendar.id}`">
              {{ calendar.name }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col">
      <!-- <div class="flex items-center gap-2 p-4">
        <Cog8ToothIcon class="h-6 w-6 rounded-full" />
        <p class="font-semibold text-dark-700">Settings</p>
      </div> -->
      <div class="flex items-center gap-2 p-4">
        <UserIcon class="h-6 w-6 rounded-full" />
        <NuxtLink :to="`/account`">
          <p class="font-semibold text-dark-700">Account</p>
        </NuxtLink>

      </div>
      <hr class="border-t border-gray-200 my-4" />
      <div class="flex items-center gap-4 p-4">
        <!-- <span
          class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500"
        >
          <span class="text-sm font-medium leading-none text-white">TW</span>
        </span> -->
        <div class="flex-row items-center">
          <!-- <p class="text-sm text-dark-700 font-semibold">biz</p> -->
          <p class="text-sm text-dark-600 font-normal">{{ user.email }}</p>
        </div>
        <ArrowRightOnRectangleIcon class="h-6 w-6 rounded-full" />
      </div>
    </div>
  </div>
</template>
